import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
// import gsap from 'gsap'
import Stats from 'stats.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import {
    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn
    
}  from './variables'

import {modelImportFn} from './modelImport.js'
import {addLightsToScene} from './lights.js'
import {generateSectionButtonsFn} from './sectionButtons.js'
import {doItOnSectionLoadFn} from './doItOnSectionLoad.js'
import {eventListenersFn} from './eventListeners.js'


console.log(val)

sceneBuildFn();

addLightsToScene(); 

//raycaster
let currentIntersect = null
let currentIntersect2 = null

const rayCaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

if (val.firstTimeLoadingPage) {
    modelImportFn();
}

window.addEventListener('mousemove', (event) =>{
    mouse.x = event.clientX / browserSizes.width * 2 - 1;
    mouse.y = -(event.clientY / browserSizes.height) * 2 + 1;
})

//display information when clicking on part
window.addEventListener('mousedown',(e)=>{
    switch(e.button){
        case 0:
            if(currentIntersect) {
                document.querySelector('.partNumber').innerHTML = currentIntersect.object.name;
                document.querySelector('.cage').innerHTML = cage[Math.floor(Math.random() * 4)];
                document.querySelector('.lcn').innerHTML = lcn[Math.floor(Math.random() * 4)];
                document.querySelector('.smrCode').innerHTML = smrCode[Math.floor(Math.random() * 4)];
                document.querySelector('.zone').innerHTML = zone[Math.floor(Math.random() * 4)];
                document.querySelector('.inst').innerHTML = inst[Math.floor(Math.random() * 4)];
            }else if (currentIntersect2){
                document.querySelector('.partNumber').innerHTML = currentIntersect2.object.name;
            }
            break;
        case 2:
            if (currentIntersect) {
                val.hiddenObj.push(currentIntersect.object);
                currentIntersect.object.layers.set(2);
            }
            break;
    }
})

eventListenersFn();

generateSectionButtonsFn();

//animation
const animate = () => {
    stats.begin();
  
    //raycaster
    rayCaster.setFromCamera(mouse, camera);

    if(val.loadedPart){
        const intersect = rayCaster.intersectObject(val.loadedPart);
    
        //constantly apply original materials to all objects in the scene
        scene.traverse((child) => {
            for (let model in categoryList) {
                if(child.name.slice(0,1) == categoryList[model]){
                    child.material = val.materialList[`mat_${categoryList[model]}`];
                }
            }
        })

        if (intersect){
            for(const item in intersect){
                // console.log(item)
                if(item == 0){
                    intersect[0].object.material = mat_001_bsc;
                }

            }

        }

        if(intersect.length){
            if(currentIntersect == null){
                console.log('mouse enters');

            }
            currentIntersect = intersect[0];
        }else {
            if(currentIntersect){
                console.log('mouse leavse');
            }
            currentIntersect = null;
        }
        doItOnSectionLoadFn();
    }

    controls.update();
    
    renderer.render(scene,camera);
    window.requestAnimationFrame(animate);
    stats.end();
}
animate();

doItOnSectionLoadFn()