import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Stats from 'stats.js'

import {
    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn
    
} from './variables'
import {modelImportFn} from './modelImport.js'
import {addLightsToScene} from './lights.js'

//generate section buttons
function generateSectionButtonsFn () {

    const sectionButtons = document.querySelector('.sectionButtons');

    for(let i=1; i<4; i++){
        const newBtnB = document.createElement('button');
        newBtnB.classList.add(`sec${i}`);
        newBtnB.append(`section${i}`);
        sectionButtons.appendChild(newBtnB);
        document.querySelector(`.sec${i}`).addEventListener('click',function(){
            val.sectionNumber = i;
            scene.remove(val.loadedPart);
            addLightsToScene();
            modelImportFn();
        })
    }

}

export {generateSectionButtonsFn}
