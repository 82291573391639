import './style.css'
import * as THREE from 'three'

import {
    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn
    
} from './variables'

import {modelImportFn} from './modelImport.js'

import {addLightsToScene} from './lights.js'

import {generateSectionButtonsFn} from './sectionButtons.js'

//Analyze imported model and add Sub-sys buttons
function doItOnSectionLoadFn(){
    if(val.doItOnSectionLoad){
        val.doItOnSectionLoad = false;

        let btns = document.querySelector('.subSysHideUnhide');
        btns.innerHTML = [];
        for(let part in val.partList){
            if (val.partList[part].length > 1) {
                const newBtnA = document.createElement('button');
                newBtnA.classList.add(`${val.partList[part][0]}`)
                newBtnA.append(`${categoryListComplete[part]}`);
                newBtnA.style.backgroundColor = colorList[part];
                btns.appendChild(newBtnA);
                document.querySelector(`.${val.partList[part][0]}`).addEventListener('click',function(){
                    for (let i in val.partList[part]){
                        if(i != 0) {
                            if (val.partList[part][i].visible){
                                val.partList[part][i].visible = false;
                            }else{
                                val.partList[part][i].visible = true;
                            }
                        }
                    }
                })
            }
        }
        document.querySelector('.loadedParts').innerText = `There are${val.allPartsNamesArr.length} parts loaded!`
    }
}

export {doItOnSectionLoadFn}