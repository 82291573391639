import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
// import gsap from 'gsap'
import Stats from 'stats.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import {
    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn
    
} from './variables'

//model import
const gltfLoader = new GLTFLoader();

function modelImportFn() {
    const loadedModels = gltfLoader.load(
        `./${val.sectionNumber}.glb`,
        (gltfStructure) =>{
            val.loadedPart = gltfStructure.scene;

            //Adjust position/size of the loaded part
            switch(val.sectionNumber){
                case 1:
                    val.loadedPart.position.z += 200;
                    val.loadedPart.position.y -= 100;
                    val.loadedPart.scale.set(200,200,200);
                    break;
                case 2:
                    val.loadedPart.position.y += -50;
                    val.loadedPart.scale.set(100,100,100);
                    break;
                case 3:
                    // loadedPart.position.x += -300;
                    val.loadedPart.position.z += 70;
                    val.loadedPart.position.y += -25;
                    val.loadedPart.scale.set(150,150,150);
                    break;
            
            }
            
            val.partList=[]; //Reset partList
            val.allPartsNamesArr = []; //Reset allPartsNamesArr

            for (let part in categoryList) {
                val.partList.push([categoryList[part]]);
            }

            //Change imported materials to THREE materials that can be modified.
            val.loadedPart.traverse ((t) => {
                if (t.isMesh) {

                    val.allPartsNamesArr.push(t.name);
                    
                    for (let part in categoryList) {
                        if(t.name.slice(0,1) == categoryList[part]){
                            t.material = val.materialList[`mat_${categoryList[part]}`]; //Assign material to the traversed mesh
                            val.partList[part].push(t); //Add traversed mesh to the partList
                        }
                    }
                }
            })
            val.doItOnSectionLoad = true;
            scene.add(val.loadedPart);
            console.log(val.loadedPart)
        }
    )
}

export {modelImportFn}