
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
// import gsap from 'gsap'
import Stats from 'stats.js'

//++++++++++++++++++++++++++++++++++++++++values
let val = { 

    sectionNumber: 1,
    firstTimeLoadingPage: true,
    doItOnSectionLoad: true,

    materialList: {},
    allPartsNamesArr: [], //Holds the names of all of loaded mesh. Format: ['meshName', 'meshName',... ]
    partList: [], //Holds all of the loaded mesh and their properties in a 2-level nested array. Format: [['A',mesh,mesh],['B',mesh,mesh],...]. mesh is an object.
    loadedPart: null,
    hiddenObj: [], //Keeps the objects that will get hidden by pressing lef mouse button.

    lightPosition: 50,
    lightIntensity: 0.35,
    ambLight: null

}


//value Change
function valChange (key, newVal) {
    val[`${key}`] = newVal;
}

//+++++++++++++++++++++++++++++++++++++++++Constants
const  browserSizes = {
    width: window.innerWidth, 
    height: window.innerHeight
}

const categoryList = ['A','B','C','D','E','F','G','H', 'I', 'J'];
const categoryListComplete = ['Crankcase & Cylinder','Injector','Inlet Manifold','Reed Valve','Propeller','Exhaust','Engine Mount','Water Inlet & Outlet', 'Structure','Landing Gear'];

const colorList = ['rgb(217,38,38)','rgb(217,164,38)','rgb(206,217,38)','rgb(80,218,38)','rgb(38,217,185)','rgb(38,123,217)','rgb(143,38,217)','rgb(206,38,217)', 'rgb(96,96,96)','rgb(192,192,192)'];
const lightList = [['topForwardRightLight',val.lightPosition,val.lightPosition,val.lightPosition],['topForwardLeftLight',val.lightPosition,val.lightPosition,-val.lightPosition],['topAftRightLight',val.lightPosition,-val.lightPosition,val.lightPosition],['topAftLeftLight',val.lightPosition,-val.lightPosition,-val.lightPosition],['bottomForwardRightLight',-val.lightPosition,val.lightPosition,val.lightPosition],['bottomForwardLeftLight',-val.lightPosition,val.lightPosition,-val.lightPosition],['bottomAftRightLight',-val.lightPosition,-val.lightPosition,val.lightPosition],['bottomAftLeftLight',-val.lightPosition,-val.lightPosition,-val.lightPosition]];

const cage = ['cage001','cage002','cage003','cage004']
const lcn = ['lcn001','lcn002','lcn003','lcn004']
const inst = ['inst001','inst002','inst003','inst004']
const zone = ['ZONE001','ZONE002','ZONE003','ZONE004']
const smrCode = ['SMR001','SMR002','SMR003','SMR004']


const stats = new Stats();
const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(75, browserSizes.width / browserSizes.height);
const renderer = new THREE.WebGLRenderer({canvas: canvas})
const controls = new OrbitControls(camera, canvas);
const axis = new THREE.AxesHelper(100);
const mat_001_bsc = new THREE.MeshBasicMaterial({color: 'lightpink'});

//+++++++++++++++++++++++++++++++++++++SCENE SETUP - BASIC SETTINGS
function sceneBuildFn () {
    
    //Frame counter
    stats.showPanel(0);
    document.body.appendChild(stats.dom);

    //scene
    scene.layers.set(1);
    scene.background = new THREE.Color('lightslategrey');
    //camera
    scene.add(camera);

    //renderer
    renderer.setSize(browserSizes.width, browserSizes.height);

    //srgb color correction
    THREE.ColorManagement.enabled = false;
    renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

    //helper
    scene.add(axis);

    //orbit control
    camera.position.set(150,200,300);
    controls.update();

    //resposive layout
    window.addEventListener('resize', ()=>{
        browserSizes.width = window.innerWidth;
        browserSizes.height - window.innerHeight

        camera.aspect = browserSizes.width / browserSizes.height;
        camera.updateProjectionMatrix();
        
        renderer.setSize(browserSizes.width, browserSizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio,2));
    })

    //Build standard material from categoryList and colorList
    for (let item in categoryList) {

        val.materialList[`mat_${categoryList[item]}`] = new THREE.MeshStandardMaterial({color: colorList[item]});
        val.materialList[`mat_${categoryList[item]}`].roughness = 0.65;

    }

    //light
    val.ambLight = new THREE.AmbientLight(0xffffff,0.25)

    for (let light in lightList){
        lightList[light][0] = new THREE.PointLight(0xffffff,val.lightIntensity);
    }


}

//export
export {

    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn
    
}







