import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
// import gsap from 'gsap'
import Stats from 'stats.js'

import {
    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn
    
} from './variables'

function addLightsToScene() {
    scene.remove(val.ambLight)
    for (let light in lightList){
        switch(val.sectionNumber){
            case 1:
                scene.remove(lightList[light][0]);
                val.lightIntensity = 0.35;
                val.ambLight.intensity = 0.25;
                lightList[light][0].position.set(lightList[light][1],lightList[light][2],lightList[light][3]);
                scene.add(lightList[light][0]);
                break;
            case 2:
                scene.remove(lightList[light][0]);
                val.lightIntensity = 2;
                val.ambLight.intensity = 1;
                lightList[light][0].position.set(lightList[light][1]*1,lightList[light][2]*1,lightList[light][3]*1);
                scene.add(lightList[light][0]);
                break;
            case 3:
                scene.remove(lightList[light][0]);
                val.lightIntensity = 0.35;
                val.ambLight.intensity = 0.25;
                lightList[light][0].position.set(lightList[light][1]*3,lightList[light][2]*3,lightList[light][3]*3);
                scene.add(lightList[light][0]);
                break;
        } 
    }
    scene.add(val.ambLight)

}

export {addLightsToScene}