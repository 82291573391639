import './style.css'
import * as THREE from 'three'
import {
    val, 
    valChange, 
    
    browserSizes, 
    categoryList, 
    categoryListComplete, 
    colorList, 
    lightList, 
    
    cage, 
    lcn, 
    inst, 
    zone, 
    smrCode,
    
    stats,
    canvas,
    scene,
    camera,
    renderer,
    controls,
    axis,
    mat_001_bsc,
    
    sceneBuildFn 
} from './variables.js'

function eventListenersFn () {
    let counter = 1;
    let array1 = [];
    let variable = null;

    document.querySelector('.btn1').addEventListener('click',function(e){
        for (let obj in val.hiddenObj){
            val.hiddenObj[obj].layers.set(0);
        }
    })

    document.querySelector('.btn2').addEventListener('click',function(f){
        val.hiddenObj[val.hiddenObj.length - 1].layers.set(0);
        val.hiddenObj.pop();
    })

    document.querySelector('.loadedParts').innerText = `There are ${val.allPartsNamesArr.length} parts loaded.`

    document.querySelector('#form').addEventListener('submit',function(f){
        f.preventDefault();
        
        array1 = [];
        counter = 1;
        variable = document.querySelector('#var').value;

        for (let i=(counter - 1)* variable; i<counter * variable ; i++){
            array1.push(val.allPartsNamesArr[i]);
        }
        
        document.querySelector('.nextRange1').innerText = `${(counter - 1) * variable} - ${counter * variable}`;
        document.querySelector('.info').innerText = array1;

    })

    document.querySelector('.nextRange').addEventListener('click',function(f){

        array1 = [];
        counter++;

        for (let i = (counter - 1) * variable; i < counter * variable; i++) {
            array1.push(val.allPartsNamesArr[i]);
        }

        document.querySelector('.nextRange1').innerText = `${(counter - 1) * variable} - ${counter * variable}`;
        document.querySelector('info').innerText = array1;

    })

    document.querySelector('.prevRange').addEventListener('click',function(f){

        array1 = [];
        if (counter > 1){
            counter--;
        }

        for (let i = (counter - 1) * variable; i < counter * variable; i++) {
            array1.push(val.allPartsNamesArr[i]);
        }

        document.querySelector('.nextRange1').innerText = `${(counter - 1) * variable} - ${counter * variable}`;
        document.querySelector('info').innerText = array1;
        
    })

    document.querySelector('.btnX').addEventListener('click',function(f){
        controls.update();
    })
}

export {eventListenersFn}